const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Work",
    url: "/work",
  },
  {
    label: "Projects",
    url: "/projects",
  },
  // {
  //   label: "Skills",
  //   url: "/skills",
  // },
  {
    label: "Contact",
    url: "/contact",
  },
]
export default links