import React from "react"
import { FooterWrapper } from "./styled"

const Footer = () => (

    <FooterWrapper>
       <section id="lab_social_icon_footer">
        <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet"/>
        <div class="container">
                <div class="text-center center-block">
                        <a href="https://www.linkedin.com/in/madison-wheeler-aaaa67a4/"><i id="social-li" class="fa fa-linkedin-square fa-3x social"></i></a>
                        <a href="https://github.com/madisonwheeler"><i id="social-gh" class="fa fa-github-square fa-3x social"></i></a>
                        <a href="mailto:madisonawheeler@gmail.com"><i id="social-em" class="fa fa-envelope-square fa-3x social"></i></a>
            </div>
        </div>
        </section>
    </FooterWrapper> 
)

export default Footer
